import LearnGrow from "./assets/learn-grow.png";
import TrustedCompany from "./assets/trusted-company.png";
import CollaborateWithUs from "./assets/collaborate-with-us.png";
import News1 from "./assets/news1.png";
import News2 from "./assets/news2.png";
import News3 from "./assets/news3.png";
import { GoArrowRight } from "react-icons/go";
import Logo from "./assets/logo.svg";
import Banner from "./assets/banner1.png";
import { MdPlayCircleOutline } from "react-icons/md";
import { MdStarRate } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Yourself from "./assets/yourself.png";
import Email from "./assets/email.png";
import ApplicationForm from "./assets/application-form.png";
import Fees from "./assets/fees.png";
import Prgrams1 from "./assets/programs1.png";
import Prgrams2 from "./assets/programs2.png";
import Prgrams3 from "./assets/programs3.png";
import { Tab, Modal, initTWE } from "tw-elements";
import { useEffect, useState } from "react";
import { CiLight } from "react-icons/ci";
import { CiDark } from "react-icons/ci";

const themes = {
  jain: {
    dark: {
      primary: "#002465",
      secondary: "#F8C000",
      error: "#d32f2f",
      warning: "#ed6c02",
      info: "#0288d1",
      success: "#2e7d32",
    },
    light: {
      primary: "#334F83",
      secondary: "#F9CC33",
      error: "#EF5350",
      warning: "#FF9800",
      info: "#03A9F4",
      success: "#4CAF50",
    },
  },
  sharda: {
    dark: {
      primary: "#FEC200",
      secondary: "#00ADEE",
      error: "#d32f2f",
      warning: "#ed6c02",
      info: "#0288d1",
      success: "#2e7d32",
    },
    light: {
      primary: "#FECE33",
      secondary: "#33BDF1",
      error: "#ef5350",
      warning: "#ff9800",
      info: "#03a9f4",
      success: "#4caf50",
    },
  },
  vignan: {
    dark: {
      primary: "#0A4D80",
      secondary: "#EB3B3B",
      error: "#d32f2f",
      warning: "#ed6c02",
      info: "#0288d1",
      success: "#2e7d32",
    },
    light: {
      primary: "#3B7099",
      secondary: "#EF6262",
      error: "#ef5350",
      warning: "#ff9800",
      info: "#03a9f4",
      success: "#4caf50",
    },
  },
  amity: {
    dark: {
      primary: "#1A325D",
      secondary: "#FFC907",
      error: "#d32f2f",
      warning: "#ed6c02",
      info: "#0288d1",
      success: "#2e7d32",
    },
    light: {
      primary: "#475B7D",
      secondary: "#FFD338",
      error: "#ef5350",
      warning: "#ff9800",
      info: "#03a9f4",
      success: "#4caf50",
    },
  },
};

function App() {
  const [themeName, setThemeName] = useState(
    localStorage.getItem("themeName")
      ? localStorage.getItem("themeName")
      : "jain"
  );
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("isDarkMode")
      ? JSON.parse(localStorage.getItem("isDarkMode"))
      : false
  );
  const [selectedTheme, setSelectedTheme] = useState(themes[themeName].light);

  const [showWidget, setShowwidget] =  useState(false);

  useEffect(() => {
    const theme = isDarkMode ? themes[themeName].dark : themes[themeName].light;
    setSelectedTheme(theme);
  }, [isDarkMode, themeName]);

  useEffect(() => {
    initTWE({ Tab, Modal });
  }, []);

  const handleChangeTheme = (themeName) => {
    localStorage.setItem("themeName", themeName);
    setThemeName(themeName);
  };

  const handleThemeMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.setItem("isDarkMode", !isDarkMode);
  };

  return (
    <>
    <div className="absolute right-1.5 opacity-100 w-full scale-75 max-w-2xl">
    {showWidget && 
       <iframe className="w-full h-[1100px]"
       src={process.env.REACT_APP_WIDGET_URL}
       frameborder="0"
     ></iframe>
    }
    </div>
    
      <div className="sticky top-0 z-50 bg-white w-full border-b py-3 px-2 md:px-4">
        <div className="w-full h-full max-w-7xl mx-auto flex justify-between items-center gap-5">
          <div className="">
            <img
              src={Logo}
              alt="logo"
              className="w-[150px] md:w-[130px] lg:w-auto"
            />
          </div>
         
          <div className="flex items-center md:gap-5 lg:gap-10">
            <div className="hidden md:flex items-center md:gap-5 lg:gap-10 font-medium text-sm text-[#7d7d7d]">
              <div
                style={{ color: selectedTheme.primary }}
                className="cursor-pointer"
              >
                Home
              </div>
              <div className="cursor-pointer">Courses</div>
              <div className="cursor-pointer">Facilities</div>
              <div className="cursor-pointer">Testimonials</div>
              <div className="cursor-pointer">Blog</div>
            </div>
            <div className="flex items-center gap-3 lg:gap-5">
              <button
                onClick={() =>
                  !showWidget ? setShowwidget(true) : setShowwidget(false)
                }
                style={{
                  borderColor: selectedTheme.primary,
                  color: selectedTheme.primary,
                }}
                className="md:min-w-[100px] rounded border py-2.5 px-3 md:px-5 text-xs font-semibold uppercase"
                type="button"
              >
                APPLY NOW
              </button>

              <button
                style={{
                  borderColor: selectedTheme.primary,
                  backgroundColor: selectedTheme.primary,
                }}
                className="md:min-w-[100px] rounded border py-2.5 px-3 md:px-5 text-xs font-semibold uppercase text-white"
                type="button"
                onClick={() => window.open(process.env.REACT_APP_LOGIN_URL, '_blank')}
              >
                LOGIN
              </button>
              <div
                onClick={handleThemeMode}
                className="w-10 h-10 rounded-full hover:bg-gray-100 overflow-hidden flex items-center justify-center"
              >
                {isDarkMode ? (
                  <CiDark className="text-xl" />
                ) : (
                  <CiLight className="text-xl" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white pt-5 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row gap-5 md:gap-10">
          <div className="flex-1 flex flex-col gap-2 lg:gap-3 text-center md:text-left items-center md:items-start justify-center">
            <h1
              style={{ color: selectedTheme.primary }}
              className="text-3xl md:text-4xl lg:text-5xl font-semibold leading-tight"
            >
              Learn and master{" "}
              <span style={{ color: selectedTheme.secondary }}>25</span> Key
              skills
            </h1>
            <h2 className="text-lg lg:text-xl text-[#7d7d7d] font-medium">
              that make your upskilling journey successful and enriching
            </h2>
            <div className="flex justify-center md:justify-start items-center gap-3 lg:gap-5 mt-3 lg:mt-5 md:mb-5">
              <button
                style={{
                  borderColor: selectedTheme.primary,
                  backgroundColor: selectedTheme.primary,
                }}
                className="md:min-w-[200px] border py-2.5 px-5 text-sm font-semibold text-white"
                type="button"
              >
                Download Free E-Book
              </button>

              <button
                style={{
                  borderColor: selectedTheme.primary,
                  color: selectedTheme.primary,
                }}
                className="md:min-w-[200px] border py-2.5 px-5 text-sm font-semibold flex justify-center items-center gap-2"
                type="button"
                data-twe-toggle="modal"
                data-twe-target="#exampleModalCenter"
              >
                <MdPlayCircleOutline className="text-xl" /> Watch Video
              </button>
            </div>
          </div>
          <div className="flex-1 shrink-0 flex justify-end">
            <img src={Banner} alt="" className="object-cover w-full" />
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: selectedTheme.primary }}
        className="w-full py-10 px-2 md:px-4"
      >
        <div className="w-full max-w-7xl mx-auto">
          <h1 className="text-2xl md:text-3xl lg:text-4xl text-white text-center font-semibold leading-tight">
            Admissions are open!
          </h1>
          <p className="text-base text-center max-w-3xl mx-auto text-white mt-3 lg:mt-5">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut, hic!
            Tempora tempore repudiandae eum odio totam delectus doloribus quidem
            blanditiis officia consectetur sit alias, earum nulla architecto
            quaerat, eveniet ullam
          </p>
          <div className="flex flex-wrap lg:flex-nowrap justify-center gap-5 mt-10 mb-5">
            <div className="w-full max-w-xs p-5 rounded-xl bg-white flex flex-col gap-3">
              <img src={Yourself} alt="" className="w-[60px]" />
              <h1 className="font-semibold">Register yourself</h1>
              <p className="text-sm font-thin">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut,
                hic! Tempora tempore repudiandae eum odio totam delectus
                doloribus
              </p>
            </div>
            <div className="w-full max-w-xs p-5 rounded-xl bg-white flex flex-col gap-3">
              <img src={Email} alt="" className="w-[60px]" />
              <h1 className="font-semibold">Verify email</h1>
              <p className="text-sm font-thin">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut,
                hic! Tempora tempore repudiandae eum odio totam delectus
                doloribus
              </p>
            </div>
            <div className="w-full max-w-xs p-5 rounded-xl bg-white flex flex-col gap-3">
              <img src={ApplicationForm} alt="" className="w-[60px]" />
              <h1 className="font-semibold">Fill application form</h1>
              <p className="text-sm font-thin">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut,
                hic! Tempora tempore repudiandae eum odio totam delectus
                doloribus
              </p>
            </div>
            <div className="w-full max-w-xs p-5 rounded-xl bg-white flex flex-col gap-3">
              <img src={Fees} alt="" className="w-[60px]" />
              <h1 className="font-semibold">Pay fees</h1>
              <p className="text-sm font-thin">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut,
                hic! Tempora tempore repudiandae eum odio totam delectus
                doloribus
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white pt-20 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight text-center">
            Explore popular online degree programs
          </h1>

          <ul
            className="mb-5 flex list-none flex-row flex-wrap justify-center border-b-0"
            role="tablist"
            data-twe-nav-ref
          >
            <li role="presentation">
              <a
                href="#tabs-home"
                className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[twe-nav-active]:border-[#012466] data-[twe-nav-active]:text-[#012466]"
                data-twe-toggle="pill"
                data-twe-target="#tabs-home"
                data-twe-nav-active
                role="tab"
                aria-controls="tabs-home"
                aria-selected="true"
              >
                Masters
              </a>
            </li>
            <li role="presentation">
              <a
                href="#tabs-profile"
                className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[twe-nav-active]:border-[#012466] data-[twe-nav-active]:text-[#012466]"
                data-twe-toggle="pill"
                data-twe-target="#tabs-profile"
                role="tab"
                aria-controls="tabs-profile"
                aria-selected="false"
              >
                Bachelors
              </a>
            </li>
          </ul>
          <div className="">
            <div
              className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block"
              id="tabs-home"
              role="tabpanel"
              aria-labelledby="tabs-home-tab"
              data-twe-tab-active
            >
              <div className="flex justify-center flex-wrap lg:flex-nowrap gap-5">
                <div className="w-full max-w-sm border rounded-md">
                  <div className="relative w-full h-[250px] overflow-hidden p-1">
                    <img
                      src={Prgrams1}
                      alt=""
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="p-4 flex flex-col gap-2">
                    <div className="py-1 px-2 uppercase text-xs w-fit bg-[#e1f6e3] text-[#1b7524]">
                      Cyber Security
                    </div>
                    <h1 className="font-semibold">
                      Human Resource Management and Finance
                    </h1>
                    <div className="text-[#7d7d7d] text-xs flex items-center gap-1">
                      <FaRegClock /> 2 years
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center gap-3">
                      <div className="flex items-center gap-1">
                        <MdStarRate className="text-xl text-[#fd9733]" /> 5.0
                      </div>
                      <div>
                        265.7K <span className="text-[#7d7d7d]">students</span>
                      </div>
                    </div>
                    <div
                      style={{ color: selectedTheme.primary }}
                      className="flex items-center uppercase text-[10px] font-semibold cursor-pointer"
                    >
                      View program
                      <MdOutlineKeyboardArrowRight className="text-lg" />
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-sm border rounded-md">
                  <div className="relative w-full h-[250px] overflow-hidden p-1">
                    <img
                      src={Prgrams2}
                      alt=""
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="p-4 flex flex-col gap-2">
                    <div className="py-1 px-2 uppercase text-xs w-fit bg-[#e1f6e3] text-[#1b7524]">
                      Cyber Security
                    </div>
                    <h1 className="font-semibold">
                      Human Resource Management and Finance
                    </h1>
                    <div className="text-[#7d7d7d] text-xs flex items-center gap-1">
                      <FaRegClock /> 2 years
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center gap-3">
                      <div className="flex items-center gap-1">
                        <MdStarRate className="text-xl text-[#fd9733]" /> 5.0
                      </div>
                      <div>
                        265.7K <span className="text-[#7d7d7d]">students</span>
                      </div>
                    </div>
                    <div
                      style={{ color: selectedTheme.primary }}
                      className="flex items-center uppercase text-[10px] font-semibold cursor-pointer"
                    >
                      View program
                      <MdOutlineKeyboardArrowRight className="text-lg" />
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-sm border rounded-md">
                  <div className="relative w-full h-[250px] overflow-hidden p-1">
                    <img
                      src={Prgrams3}
                      alt=""
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="p-4 flex flex-col gap-2">
                    <div className="py-1 px-2 uppercase text-xs w-fit bg-[#e1f6e3] text-[#1b7524]">
                      Cyber Security
                    </div>
                    <h1 className="font-semibold">
                      Human Resource Management and Finance
                    </h1>
                    <div className="text-[#7d7d7d] text-xs flex items-center gap-1">
                      <FaRegClock /> 2 years
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center gap-3">
                      <div className="flex items-center gap-1">
                        <MdStarRate className="text-xl text-[#fd9733]" /> 5.0
                      </div>
                      <div>
                        265.7K <span className="text-[#7d7d7d]">students</span>
                      </div>
                    </div>
                    <div
                      style={{ color: selectedTheme.primary }}
                      className="flex items-center uppercase text-[10px] font-semibold cursor-pointer"
                    >
                      View program
                      <MdOutlineKeyboardArrowRight className="text-lg" />
                    </div>
                  </div>
                </div>
              </div>
              <button
                style={{ backgroundColor: selectedTheme.primary }}
                className="mx-auto text-white mt-10 text-sm flex gap-3 items-center px-5 py-2.5"
              >
                Explore All Programs <GoArrowRight className="text-xl" />
              </button>
            </div>
            <div
              className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block"
              id="tabs-profile"
              role="tabpanel"
              aria-labelledby="tabs-profile-tab"
            >
              <div className="flex justify-center flex-wrap lg:flex-nowrap gap-5">
                <div className="w-full max-w-sm border rounded-md">
                  <div className="relative w-full h-[250px] overflow-hidden p-1">
                    <img
                      src={Prgrams1}
                      alt=""
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="p-4 flex flex-col gap-2">
                    <div className="py-1 px-2 uppercase text-xs w-fit bg-[#e1f6e3] text-[#1b7524]">
                      Cyber Security
                    </div>
                    <h1 className="font-semibold">
                      Human Resource Management and Finance
                    </h1>
                    <div className="text-[#7d7d7d] text-xs flex items-center gap-1">
                      <FaRegClock /> 2 years
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center gap-3">
                      <div className="flex items-center gap-1">
                        <MdStarRate className="text-xl text-[#fd9733]" /> 5.0
                      </div>
                      <div>
                        265.7K <span className="text-[#7d7d7d]">students</span>
                      </div>
                    </div>
                    <div
                      style={{ color: selectedTheme.primary }}
                      className="flex items-center uppercase text-[10px] font-semibold cursor-pointer"
                    >
                      View program
                      <MdOutlineKeyboardArrowRight className="text-lg" />
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-sm border rounded-md">
                  <div className="relative w-full h-[250px] overflow-hidden p-1">
                    <img
                      src={Prgrams2}
                      alt=""
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="p-4 flex flex-col gap-2">
                    <div className="py-1 px-2 uppercase text-xs w-fit bg-[#e1f6e3] text-[#1b7524]">
                      Cyber Security
                    </div>
                    <h1 className="font-semibold">
                      Human Resource Management and Finance
                    </h1>
                    <div className="text-[#7d7d7d] text-xs flex items-center gap-1">
                      <FaRegClock /> 2 years
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center gap-3">
                      <div className="flex items-center gap-1">
                        <MdStarRate className="text-xl text-[#fd9733]" /> 5.0
                      </div>
                      <div>
                        265.7K <span className="text-[#7d7d7d]">students</span>
                      </div>
                    </div>
                    <div
                      style={{ color: selectedTheme.primary }}
                      className="flex items-center uppercase text-[10px] font-semibold cursor-pointer"
                    >
                      View program
                      <MdOutlineKeyboardArrowRight className="text-lg" />
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-sm border rounded-md">
                  <div className="relative w-full h-[250px] overflow-hidden p-1">
                    <img
                      src={Prgrams3}
                      alt=""
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                  <div className="p-4 flex flex-col gap-2">
                    <div className="py-1 px-2 uppercase text-xs w-fit bg-[#e1f6e3] text-[#1b7524]">
                      Cyber Security
                    </div>
                    <h1 className="font-semibold">
                      Human Resource Management and Finance
                    </h1>
                    <div className="text-[#7d7d7d] text-xs flex items-center gap-1">
                      <FaRegClock /> 2 years
                    </div>
                  </div>
                  <hr />
                  <div className="p-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center gap-3">
                      <div className="flex items-center gap-1">
                        <MdStarRate className="text-xl text-[#fd9733]" /> 5.0
                      </div>
                      <div>
                        265.7K <span className="text-[#7d7d7d]">students</span>
                      </div>
                    </div>
                    <div
                      style={{ color: selectedTheme.primary }}
                      className="flex items-center uppercase text-[10px] font-semibold cursor-pointer"
                    >
                      View program
                      <MdOutlineKeyboardArrowRight className="text-lg" />
                    </div>
                  </div>
                </div>
              </div>
              <button
                style={{ backgroundColor: selectedTheme.primary }}
                className="mx-auto text-white mt-10 text-sm flex gap-3 items-center px-5 py-2.5"
              >
                Explore All Programs <GoArrowRight className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white py-20 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row gap-5 md:gap-10">
          <div className="flex-1 shrink-0 flex justify-center items-center md:items-start md:justify-start">
            <img src={LearnGrow} alt="" className="w-full" />
          </div>
          <div className="flex-1">
            <h2
              style={{ color: selectedTheme.primary }}
              className="text-sm md:text-base uppercase font-semibold"
            >
              What we are
            </h2>
            <h1
              style={{ color: selectedTheme.primary }}
              className="mt-2 text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight"
            >
              Learn and Grow your{" "}
              <span style={{ color: selectedTheme.secondary }}>skills</span>{" "}
              From Anywhere
            </h1>
            <p className="text-base md:text-lg mt-4 lg:mt-5 mb-10 lg:mb-14">
              Jain Online: Empowering remote skill development with accessible
              resources, fostering growth from any location. Learn and Grow from
              Anywhere - Accessible skill development anytime, anywhere.
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-8">
              <div className="">
                <h1
                  style={{ color: selectedTheme.primary }}
                  className="font-light text-center text-base"
                >
                  <span className="font-bold">40+</span> Electives
                </h1>
                <p className="text-sm mt-2">
                  Explore the most sought after online degree programs with the
                  electives like Data science, Computer science etc
                </p>
              </div>
              <div className="">
                <h1
                  style={{ color: selectedTheme.primary }}
                  className="font-light text-center text-base"
                >
                  <span className="font-bold">100+</span> World Renowned
                  faculties
                </h1>
                <p className="text-sm mt-2">
                  Explore the most sought after online degree programs with the
                  electives like Data science, Computer science etc
                </p>
              </div>
              <div className="">
                <h1
                  style={{ color: selectedTheme.primary }}
                  className="font-light text-center text-base"
                >
                  <span className="font-bold">2+</span> Value added programs
                </h1>
                <p className="text-sm mt-2">
                  Explore the most sought after online degree programs with the
                  electives like Data science, Computer science etc
                </p>
              </div>
              <div className="">
                <h1
                  style={{ color: selectedTheme.primary }}
                  className="font-light text-center text-base"
                >
                  <span className="font-bold">40+</span> Electives
                </h1>
                <p className="text-sm mt-2">
                  Explore the most sought after online degree programs with the
                  electives like Data science, Computer science etc
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#edf6ff] py-10 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto flex flex-col md:flex-row gap-6 md:gap-10 lg:gap-14">
          <div className="w-full md:w-5/12">
            <h2
              style={{ color: selectedTheme.primary }}
              className="text-sm md:text-base uppercase font-semibold"
            >
              Hiring corporates
            </h2>
            <h1
              style={{ color: selectedTheme.primary }}
              className="mt-2 text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight"
            >
              Trusted Company Around The World!
            </h1>
            <p className="text-base md:text-lg mt-4 lg:mt-5">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut, hic!
              Tempora tempore repudiandae eum odio totam delectus doloribus
              quidem blanditiis officia consectetur sit alias, earum nulla
              architecto quaerat, eveniet ullam
            </p>
          </div>
          <div className="w-full md:w-7/12 flex items-center">
            <img src={TrustedCompany} alt="" className="w-full" />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#fcf9f0] py-10 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight max-w-3xl mx-auto text-center">
            Leading Global Professional Bodies that collaborate with us
          </h1>
          <img
            src={CollaborateWithUs}
            alt=""
            className="w-full mt-7 md:mt-10"
          />
        </div>
      </div>

      <div className="w-full bg-[#fef4d9] py-10 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto text-lg text-center">
          World's leading and largest professional body of management
          accountants helping individuals and businesses to succeed
        </div>
      </div>

      <div className="w-full bg-white py-20 px-2 md:px-4">
        <div className="w-full max-w-7xl mx-auto">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold leading-tight text-center">
            News & Media
          </h1>
          <div className="flex justify-center flex-wrap lg:flex-nowrap gap-5 mt-7">
            <div className="w-full max-w-sm border p-1 rounded-md">
              <div className="relative w-full h-[250px] overflow-hidden rounded-md">
                <img
                  src={News1}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-base text-center my-5 px-2 leading-normal">
                Jain University, Zambia forge educational partnership at
                National youth indaba
              </p>
              <p
                style={{ color: selectedTheme.secondary }}
                className="text-center text-sm mb-1"
              >
                Shiksha
              </p>
              <p className="text-[#7d7d7d] text-center text-sm mb-3">
                21 March 2024
              </p>
            </div>
            <div className="w-full max-w-sm border p-1 rounded-md">
              <div className="relative w-full h-[250px] overflow-hidden rounded-md">
                <img
                  src={News2}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-base text-center my-5 px-2 leading-normal">
                Jain University, Zambia forge educational partnership at
                National youth indaba
              </p>
              <p
                style={{ color: selectedTheme.secondary }}
                className="text-center text-sm mb-1"
              >
                Shiksha
              </p>
              <p className="text-[#7d7d7d] text-center text-sm mb-3">
                21 March 2024
              </p>
            </div>
            <div className="w-full max-w-sm border p-1 rounded-md">
              <div className="relative w-full h-[250px] overflow-hidden rounded-md">
                <img
                  src={News3}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-base text-center my-5 px-2 leading-normal">
                Jain University, Zambia forge educational partnership at
                National youth indaba
              </p>
              <p
                style={{ color: selectedTheme.secondary }}
                className="text-center text-sm mb-1"
              >
                Shiksha
              </p>
              <p className="text-[#7d7d7d] text-center text-sm mb-3">
                21 March 2024
              </p>
            </div>
          </div>
          <button
            style={{ backgroundColor: selectedTheme.primary }}
            className="mx-auto text-white mt-10 text-sm flex gap-3 items-center px-5 py-2.5"
          >
            View More <GoArrowRight className="text-xl" />
          </button>
        </div>
      </div>

      <div
        data-twe-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="exampleModalCenter"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-twe-modal-dialog-ref
          class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[600px]"
        >
          <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow- outline-none dark:bg-surface-dark">
            <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 px-4 py-2 dark:border-white/10">
              <h5
                className="text-xl font-semibold leading-normal text-neutral-800 dark:text-neutral-200"
                id="exampleModalCenterTitle"
              >
                Kyndryl - The Heart of Progress
              </h5>

              <button
                type="button"
                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-twe-modal-dismiss
                aria-label="Close"
              >
                <span class="[&>svg]:h-6 [&>svg]:w-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </div>

            <div className="relative p-2">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/kCPo-A1aGH8?si=X38HLcL9UkaEtWd1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-2 right-2 z-50 flex flex-col gap-2">
        <div
          onClick={() => handleChangeTheme("jain")}
          className={`${
            themeName === "jain" ? "opacity-100" : "opacity-40"
          } cursor-pointer w-8 h-8 shadow-2xl rounded-full border-2 border-white overflow-hidden hover:opacity-100 bg-[#002465] text-white flex items-center justify-center`}
        ></div>
        <div
          onClick={() => handleChangeTheme("sharda")}
          className={`${
            themeName === "sharda" ? "opacity-100" : "opacity-40"
          } cursor-pointer w-8 h-8 shadow-2xl rounded-full border-2 border-white overflow-hidden hover:opacity-100 bg-[#FEC200] text-white flex items-center justify-center`}
        ></div>
        <div
          onClick={() => handleChangeTheme("vignan")}
          className={`${
            themeName === "vignan" ? "opacity-100" : "opacity-40"
          } cursor-pointer w-8 h-8 shadow-2xl rounded-full border-2 border-white overflow-hidden hover:opacity-100 bg-[#0A4D80] text-white flex items-center justify-center`}
        ></div>
        <div
          onClick={() => handleChangeTheme("amity")}
          className={`${
            themeName === "amity" ? "opacity-100" : "opacity-40"
          } cursor-pointer w-8 h-8 shadow-2xl rounded-full border-2 border-white overflow-hidden hover:opacity-100 bg-[#1A325D] text-white flex items-center justify-center`}
        ></div>
      </div>
    </>
  );
}

export default App;
